import { css, cx } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { AgpProfile } from '@/uiKit/molecules/graphs/AgpProfile';

type GlycemiaStatsCGMProps = {};

export const GlycemiaStatsCGMNoData = ({}: GlycemiaStatsCGMProps) => {
  const { t } = useTranslation();

  const styles = useStyles(makeStyles);

  return (
    <Card>
      <CardHeader title={t('glycemia.title')} />
      <CardContent className={styles.content}>
        <div className={styles.row}>
          <div className={cx(styles.col, styles.itemsRow)}>
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.averageSensor')}
              precision={0}
              value={0}
              unit={'mg/dL'}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.estimatedHba1c')}
              value={0}
              precision={0}
              unit={'%'}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.coefVariation')}
              value={0}
              precision={0}
              unit={'%'}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.sensorUsage')}
              value={0}
              precision={0}
              unit={'%'}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.nbHypo')}
              value={0}
              precision={0}
            />
          </div>
        </div>
        <div className={styles.row}>
          <AgpProfile agp={[]} />
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    display: flex;
    flex-direction: column;
  `,
  col: css`
    width: 50%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
  `,
  itemsRow: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(16)};
  `,
  item: css``,
  gap: css``,
});
