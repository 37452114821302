import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';

import { useStyles } from '@/hooks/useStyles';

import { GlycemiaStatsBGM } from './GlycemiaStatsBGM';
import { InsulinStatsBGM } from './InsulinStatsBGM';

type DiabetesStatsProps = {
  patientId: string;
  to: DateTime;
  from: DateTime;
  insulinInjectionMethod?: string;
};

export const DiabetesStatsBGM = ({
  patientId,
  from,
  to,
  insulinInjectionMethod,
}: DiabetesStatsProps) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <GlycemiaStatsBGM
        patientId={patientId}
        from={from}
        to={to}
        className={styles.childLeft}
      />
      <InsulinStatsBGM
        patientId={patientId}
        from={from}
        to={to}
        insulinInjectionMethod={insulinInjectionMethod}
        className={styles.childRight}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    gap: ${theme.spacing(8)};
    flex-direction: row;
    width: 100%;
    align-items: stretch;
  `,
  childLeft: css`
    flex: 4;
  `,
  childRight: css`
    flex: 6;
  `,
});
