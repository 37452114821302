import React from 'react';

import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries';
import { DiabetesStatsBGM } from '@/uiKit/organisms/DiabetesStats/DiabetesStatsBGM';
import { DiabetesStatsCGM } from '@/uiKit/organisms/DiabetesStats/DiabetesStatsCGM';
import { GlycemiaStats } from '@/uiKit/organisms/GlycemiaStats/GlycemiaStats';
import { InsulinStats } from '@/uiKit/organisms/InsulinStats/InsulinStats';

export const MonitoringStats: React.FC = () => {
  const state = usePatientMonitoringContext(state => state);
  const { data: featureFlags } = Queries.user.useFeatureFlags({});
  const { data: diabetesDataDisplayMode } =
    Queries.practitioner.useDiabetesDisplayMode(state.patient.id);

  if (!featureFlags?.['indicators_from_monitoring_engine']) {
    return (
      <>
        <GlycemiaStats
          patientId={state.patient.id}
          from={state.from}
          to={state.to}
        />
        <InsulinStats
          patientId={state.patient.id}
          from={state.from}
          to={state.to}
        />
      </>
    );
  }

  if (!diabetesDataDisplayMode) {
    return null;
  }

  switch (diabetesDataDisplayMode.glycemia_indicators_display_mode) {
    case 'bgm':
    case 't12_bgm':
    case 'dg_bgm':
      return (
        <DiabetesStatsBGM
          patientId={state.patient.id}
          from={state.from}
          to={state.to}
          insulinInjectionMethod={
            diabetesDataDisplayMode.insulin_indicators_display_mode ==
            'continuous'
              ? 'pump'
              : 'pen'
          }
        />
      );
    case 'cgm':
    case 't12_cgm':
    default:
      return (
        <DiabetesStatsCGM
          patientId={state.patient.id}
          from={state.from}
          to={state.to}
          insulinInjectionMethod={
            diabetesDataDisplayMode.insulin_indicators_display_mode ==
            'continuous'
              ? 'pump'
              : 'pen'
          }
        />
      );
  }
};
