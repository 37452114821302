import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { InsulinIndicatorsResponse } from '@/models/InsulinStatsModel';

import { Dot } from '../../atoms/Dot';
import { InsulinStatsWheel } from '../../molecules/graphs/InsulinStatsWheel';
import { InsulinStatItem } from '../InsulinStats/InsulinStatItem';

type InsulinDistributionProps = {
  insulinStats: InsulinIndicatorsResponse;
  className?: string;
  wheelSize: 'big' | 'small';
};

export const InsulinDistribution = ({
  insulinStats,
  wheelSize,
  className,
}: InsulinDistributionProps) => {
  const theme = useTheme();
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const basal = insulinStats?.averageQuanityInjected.basal ?? 0;
  const bolus = insulinStats?.averageQuanityInjected.bolus ?? 0;

  const totalAverageInjected = basal + bolus;

  const ratioBasal =
    totalAverageInjected === 0 ? 0 : basal / totalAverageInjected;

  const ratioBolus =
    totalAverageInjected === 0 ? 0 : bolus / totalAverageInjected;
  return (
    <div className={cx(styles.container, className)}>
      <InsulinStatsWheel
        className={styles.wheel}
        basal={basal}
        bolus={bolus}
        size={wheelSize}
      />
      <div className={styles.legend}>
        <InsulinStatItem
          legend={
            <Dot
              backgroundColor={theme.palette.insulin.basal.contrastText}
              borderColor={theme.palette.insulin.basal.main}
            />
          }
          title={`${t('insulin.slowInsulinPerDay')}`}
          percentage={ratioBasal}
          percentagePrecision={0}
          value={insulinStats?.averageQuanityInjected.basal ?? 0}
          unit="U"
          precision={1}
        />
        <InsulinStatItem
          legend={
            <Dot
              backgroundColor={theme.palette.insulin.bolus.contrastText}
              borderColor={theme.palette.insulin.bolus.main}
            />
          }
          title={`${t('insulin.fastInsulinPerDay')}`}
          percentage={ratioBolus}
          percentagePrecision={0}
          value={insulinStats?.averageQuanityInjected.bolus ?? 0}
          unit="U"
          precision={1}
        />
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  wheel: css``,
  legend: css`
    flex: 0 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
    justify-content: space-between;
  `,
  container: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(16)};
    align-items: center;
    flex: 1 1 0;
  `,
});
