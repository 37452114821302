import { css, cx } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { AgpProfile } from '@/uiKit/molecules/graphs/AgpProfile';
import { GlycemiaBars } from '@/uiKit/molecules/graphs/GlycemiaBars';

import { compareValues } from '../GlycemiaStats/Common';
import { GlycemiaStatsCGMNoData } from './GlycemiaStatsCGMNoData';

type GlycemiaStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
};

export const GlycemiaStatsCGM = ({
  patientId,
  from,
  to,
}: GlycemiaStatsProps) => {
  const { t } = useTranslation();
  const { data: glycemiaStats } = Queries.diabetes.useGlycemiaIndicators(
    {
      patientId,
      from: from,
      to: to,
      glycemiaMeasurmentMethod: 'cgm',
    },
    {
      enabled: !!patientId,
    },
  );

  const sizeOfRange = to.diff(from, 'days').days;
  const { data: previousGlycemiaStats } =
    Queries.diabetes.useGlycemiaIndicators(
      {
        patientId,
        from: from.minus({ days: sizeOfRange + 1 }),
        to: from.minus({ days: 1 }),
        glycemiaMeasurmentMethod: 'cgm',
      },
      {
        enabled: !!patientId,
      },
    );

  const styles = useStyles(makeStyles);

  const { data: glycemiaParameters } =
    Queries.diabetes.useParameters(patientId);

  if (!glycemiaStats || !glycemiaParameters) {
    return <GlycemiaStatsCGMNoData />;
  }

  return (
    <Card>
      <CardHeader title={t('glycemia.title')} />
      <CardContent className={styles.content}>
        <div className={styles.row}>
          <div className={cx(styles.col, styles.itemsRow)}>
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.averageSensor')}
              value={glycemiaStats.meanGlycemia}
              unit={'mg/dL'}
              precision={0}
              variation={compareValues(
                glycemiaStats.meanGlycemia,
                previousGlycemiaStats?.meanGlycemia,
              )}
              lowerIsBetter
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.estimatedHba1c')}
              value={glycemiaStats.hb1ac as number}
              unit={'%'}
              precision={1}
              variation={compareValues(
                glycemiaStats.hb1ac,
                previousGlycemiaStats?.hb1ac,
              )}
              lowerIsBetter
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.coefVariation')}
              value={(glycemiaStats.coefficientOfVariation as number) * 100}
              unit={'%'}
              precision={0}
              variation={
                compareValues(
                  glycemiaStats.coefficientOfVariation,
                  previousGlycemiaStats?.coefficientOfVariation,
                ) * 100
              }
              lowerIsBetter
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.sensorUsage')}
              value={(glycemiaStats.usagePercentage as number) * 100}
              unit={'%'}
              precision={0}
              variation={
                compareValues(
                  glycemiaStats.usagePercentage,
                  previousGlycemiaStats?.usagePercentage,
                ) * 100
              }
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.nbHypo')}
              value={glycemiaStats.numberOfHypoglycemia}
              precision={0}
              variation={compareValues(
                glycemiaStats.numberOfHypoglycemia,
                previousGlycemiaStats?.numberOfHypoglycemia,
              )}
              lowerIsBetter
            />
          </div>
          {glycemiaStats.glycemiaRanges && (
            <div className={styles.col}>
              <Typography variant="body">
                {t('charts.timeBetweenThresholds')}
              </Typography>
              <GlycemiaBars
                width={300}
                height={275}
                data={glycemiaStats.glycemiaRanges}
                glycemiaThresholds={glycemiaParameters}
                className={styles.padding}
              />
            </div>
          )}
        </div>
        {glycemiaStats.agpProfile && (
          <div className={styles.row}>
            <AgpProfile
              agp={glycemiaStats.agpProfile}
              glycemiaParameters={glycemiaParameters}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    display: flex;
    flex-direction: column;
  `,
  col: css`
    width: 45%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  itemsRow: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(16)};
  `,
  padding: css`
    padding-left: ${theme.spacing(4)};
    display: flex;
    justify-content: center;
  `,
  item: css``,
  gap: css``,
});
