import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Row } from '@/components/layout/Flex';
import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { GlobalStatsGlycemiaSensor } from '@/models/GlobalStatsModel';
import {
  calculateHbA1C,
  compareValues,
} from '@/uiKit/organisms/GlycemiaStats/Common.ts';

export type GlycemiaSensorValuesProps = {
  data: GlobalStatsGlycemiaSensor;
};

/**
 * @deprecated This is being switched to the ME
 */
export const GlycemiaSensorValues: React.FC<GlycemiaSensorValuesProps> = ({
  data,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  return (
    <Row className={styles.container}>
      <StatItem
        className={styles.item}
        title={t('pages.patientMonitoring.glycemia.averageSensor')}
        value={data.mean_glycemia ?? 0}
        unit={'mg/dL'}
        precision={0}
        variation={compareValues(data.mean_glycemia, data.prev_mean_glycemia)}
        lowerIsBetter
      />
      <StatItem
        className={styles.item}
        title={t('pages.patientMonitoring.glycemia.estimatedHba1c')}
        value={calculateHbA1C(data.mean_glycemia)}
        unit={'%'}
        precision={1}
        variation={compareValues(
          calculateHbA1C(data.mean_glycemia),
          calculateHbA1C(data.prev_mean_glycemia),
        )}
        lowerIsBetter
      />
      <StatItem
        className={styles.item}
        title={t('pages.patientMonitoring.glycemia.coefVariation')}
        value={(data.coefficient_of_variation ?? 0) * 100}
        unit={'%'}
        precision={0}
        variation={
          compareValues(
            data.coefficient_of_variation,
            data.prev_coefficient_of_variation,
          ) * 100
        }
        lowerIsBetter
      />
      <StatItem
        className={styles.item}
        title={t('pages.patientMonitoring.glycemia.sensorUsage')}
        value={data.usage_percent}
        unit={'%'}
        precision={0}
        variation={compareValues(data.usage_percent, data.prev_usage_percent)}
      />
      <StatItem
        className={styles.item}
        title={t('pages.patientMonitoring.glycemia.nbHypo')}
        value={data.count_hypoglycemia}
        precision={0}
        variation={compareValues(
          data.count_hypoglycemia,
          data.prev_count_hypoglycemia,
        )}
        lowerIsBetter
      />
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    column-gap: ${theme.spacing(8)};
    row-gap: ${theme.spacing(12)};
    flex-wrap: wrap;
  `,
  item: css`
    flex: 0 1 30%;
  `,
});
