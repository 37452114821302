import { QueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { DiabetesRepository } from '@/io/repository/DiabetesRepository';
import { InsulinRepository } from '@/io/repository/InsulinRepository.ts';
import { ManagePatientRepository } from '@/io/repository/ManagePatientRepository.ts';
import { makeCGMDailyGraphData } from '@/models/CGMDailyGraphData.ts';
import {
  EditBGMGlycemiaParameters,
  EditCGMGlycemiaParameters,
  GlycemiaParameters,
} from '@/models/DiabetesDataModel.ts';
import { makeQueryKey, stripQueryResult } from '@/queries/Queries';
import { createInfiniteQuery } from '@/queries/utils/CreateInfiniteQuery';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';
import {
  DiabetesDataParams,
  getDiabetesDataNextPageParam,
  getDiabetesDataPreviousPageParam,
} from '@/queries/utils/DiabetesQueryUtils.ts';

type DiabetesRequest = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
};

type BGMLogbookRequest = DiabetesRequest & {
  order: 'downIsFuture' | 'downIsPast';
};

// When the user clicks for more data, load 1 week (business rule)
export const BGM_DOWN_IS_FUTURE_WEEKS = 1;
// When the user scrolls to the bottom, load 6 weeks (arbitrary number, find balance between request speed and scroll locking at the bottom)
export const BGM_DOWN_IS_PAST_WEEKS = 6;

type InsulinIndicatorsRequest = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
  insulinInjectionMethod: string;
};

type GlycemiaIndicatorRequest = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
  glycemiaMeasurementMethod: string;
};

export class DiabetesQueries {
  constructor(
    private readonly diabetes: DiabetesRepository = new DiabetesRepository(),
    private readonly insulin: InsulinRepository = new InsulinRepository(),
    private readonly patient: ManagePatientRepository = new ManagePatientRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useInfiniteCGMDailyGraph = createInfiniteQuery(
    'infinite-cgm-daily-graph',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISO(), to?.toISO()),
    async ({ patientId, from, to }: DiabetesDataParams) => {
      const start = from.startOf('day');
      const end = to.endOf('day');
      const [
        parametersResult,
        glycemiaResult,
        insulinResult,
        foodResult,
        activityResult,
        reportsResult,
      ] = await Promise.all([
        this.diabetes.getCGMParameters(patientId),
        this.diabetes.getGlycemia(patientId, start, end),
        this.insulin.getInsulin(patientId, start, end),
        this.diabetes.getFood(patientId, start, end),
        this.diabetes.getActivity(patientId, start, end),
        this.diabetes.getReports(patientId, start, end),
      ]);

      return makeCGMDailyGraphData(
        start,
        end,
        stripQueryResult(parametersResult),
        stripQueryResult(glycemiaResult),
        stripQueryResult(insulinResult),
        stripQueryResult(foodResult),
        stripQueryResult(activityResult),
        stripQueryResult(reportsResult),
      );
    },
    (_, __, lastPageParam) => getDiabetesDataNextPageParam(lastPageParam),
    {
      getPreviousPageParam: (_, __, firstPageParam) =>
        getDiabetesDataPreviousPageParam(firstPageParam),
      maxPages: 50,
    },
  );

  useInfiniteBGMLogbook = createInfiniteQuery(
    'infinite-bgm-logbook',
    ({ patientId, order, from, to }) =>
      makeQueryKey(patientId, order, from?.toISO(), to?.toISO()),
    async ({ patientId, from, to }: BGMLogbookRequest) =>
      stripQueryResult(await this.diabetes.getBGMLogbook(patientId, from, to)),
    (_, __, lastPageParam) =>
      lastPageParam.order === 'downIsPast'
        ? {
            patientId: lastPageParam.patientId,
            to: lastPageParam.from.minus({ day: 1 }),
            from: lastPageParam.from
              .minus({ week: BGM_DOWN_IS_PAST_WEEKS })
              .startOf('week'),
            order: lastPageParam.order,
          }
        : {
            patientId: lastPageParam.patientId,
            to: lastPageParam.from.minus({ day: 1 }),
            from: lastPageParam.from.minus({ week: BGM_DOWN_IS_FUTURE_WEEKS }),
            order: lastPageParam.order,
          },
  );

  useParameters = createQuery(
    'parameters',
    patientId => [patientId],
    async (patientId: string): Promise<GlycemiaParameters> => {
      const displayMode = stripQueryResult(
        await this.patient.getDiabetesDisplayMode(patientId),
      );
      if (displayMode.glycemia_data_display_mode === 'logbook') {
        const parameters = stripQueryResult(
          await this.diabetes.getBGMParameters(patientId),
        );
        return { type: 'BGM', parameters };
      } else {
        const parameters = stripQueryResult(
          await this.diabetes.getCGMParameters(patientId),
        );
        return { type: 'CGM', parameters };
      }
    },
  );

  useMedicalDevices = createQuery(
    'medical-devices',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getMedicalDevices(patientId)),
  );

  /**
   * @deprecated Moving to useGlycemiaIndicators
   */
  useTargetDurationStats = createQuery(
    'target-duration-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: DiabetesRequest) =>
      stripQueryResult(
        await this.diabetes.getTargetDurationStats(patientId, from, to),
      ),
  );

  /**
   * @deprecated Moving to useGlycemiaIndicators and useInsulinIndicators
   */
  useGlobalStats = createQuery(
    'global-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: DiabetesRequest) =>
      stripQueryResult(await this.diabetes.getGlobalStats(patientId, from, to)),
  );

  /**
   * @deprecated Moving to useGlycemiaIndicators
   */
  useAGPStats = createQuery(
    'agp-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: DiabetesRequest) =>
      stripQueryResult(await this.diabetes.getAGPStats(patientId, from, to)),
  );

  useGlycemiaIndicators = createQuery(
    'glycemia-indicators',
    ({ patientId, from, to, glycemiaMeasurementMethod }) =>
      makeQueryKey(
        patientId,
        from?.toISODate(),
        to?.toISODate(),
        glycemiaMeasurementMethod,
      ),
    async ({
      patientId,
      from,
      to,
      glycemiaMeasurementMethod,
    }: GlycemiaIndicatorRequest) =>
      stripQueryResult(
        await this.diabetes.getGlycemiaIndicators(
          patientId,
          from,
          to,
          glycemiaMeasurementMethod,
        ),
      ),
  );

  useInsulinIndicators = createQuery(
    'insulin-indicators',
    ({ patientId, from, to, insulinInjectionMethod }) =>
      makeQueryKey(
        patientId,
        from?.toISODate(),
        to?.toISODate(),
        insulinInjectionMethod,
      ),
    async ({
      patientId,
      from,
      to,
      insulinInjectionMethod,
    }: InsulinIndicatorsRequest) =>
      stripQueryResult(
        await this.diabetes.getInsulinIndicators(
          patientId,
          from,
          to,
          insulinInjectionMethod,
        ),
      ),
  );

  /*******************************/
  /*********** MUTATIONS *********/
  /*******************************/

  useEditCGMParameters = createMutation(
    'edit-cgm-parameters',
    async (data: EditCGMGlycemiaParameters) =>
      stripQueryResult(await this.diabetes.editCGMParameters(data)),
    {
      onSuccess: (parameters, _, { queryClient }) => {
        this.useParameters.manualUpdate(queryClient, parameters.id, () => ({
          type: 'CGM',
          parameters,
        }));
        this.invalidateAllStats(queryClient, parameters.id);
      },
    },
  );

  useEditBGMParameters = createMutation(
    'edit-bgm-parameters',
    async (data: EditBGMGlycemiaParameters) =>
      stripQueryResult(await this.diabetes.editBGMParameters(data)),
    {
      onSuccess: (parameters, _, { queryClient }) => {
        this.useParameters.manualUpdate(queryClient, parameters.id, () => ({
          type: 'BGM',
          parameters,
        }));
        this.invalidateAllStats(queryClient, parameters.id);
      },
    },
  );

  useUploadFile = createMutation(
    'upload-file',
    async ({
      patientId,
      file,
      timezone,
    }: {
      patientId: string;
      file: File;
      timezone?: string;
    }) =>
      stripQueryResult(
        await this.diabetes.uploadFile(patientId, file, timezone),
      ),
  );

  /*******************************/
  /************ HELPERS **********/
  /*******************************/

  invalidateAllStats = (queryClient: QueryClient, patientId: string) => {
    this.useInfiniteCGMDailyGraph.invalidate(queryClient, { patientId });
    this.useTargetDurationStats.invalidate(queryClient, { patientId });
    this.useGlobalStats.invalidate(queryClient, { patientId });
    this.useAGPStats.invalidate(queryClient, { patientId });
  };
}
