import React from 'react';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { BarRounded } from '@visx/shape';
import { Text } from '@visx/text';

import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel';
import { GlycemiaDistribution } from '@/models/GlycemiaStatsModel';
import { formatNumber } from '@/utils/math';

import { ThresholdStep } from '../thresholds/ThresholdStep';

type GlycemiaBarsProps = {
  data: GlycemiaDistribution;
  width: number;
  height: number;
  glycemiaThresholds: CGMGlycemiaParameters;
  className?: string;
};

// TODO fix the bars paddding
export const GlycemiaBars: React.FC<GlycemiaBarsProps> = ({
  data,
  glycemiaThresholds,
  width,
  height,
  className,
}) => {
  // bounds
  const margin = { top: 0, left: 100, right: 60, bottom: 0 };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const theme = useTheme();

  const fillColorScale: Record<ThresholdStep, string> = {
    severeHyperglycemia:
      theme.palette.glycemia.severeHyperglycemia.contrastText,
    hyperglycemia: theme.palette.glycemia.hyperglycemia.contrastText,
    normal: theme.palette.glycemia.normal.contrastText,
    hypoglycemia: theme.palette.glycemia.hypoglycemia.contrastText,
    severeHypoglycemia: theme.palette.glycemia.severeHypoglycemia.contrastText,
  };

  const borderColorScale = {
    severeHyperglycemia: theme.palette.glycemia.severeHyperglycemia.light,
    hyperglycemia: theme.palette.glycemia.hyperglycemia.light,
    normal: theme.palette.glycemia.normal.light,
    hypoglycemia: theme.palette.glycemia.hypoglycemia.light,
    severeHypoglycemia: theme.palette.glycemia.severeHypoglycemia.light,
  };

  const textColorScale = {
    severeHyperglycemia: theme.palette.glycemia.severeHyperglycemia.main,
    hyperglycemia: theme.palette.glycemia.hyperglycemia.main,
    normal: theme.palette.glycemia.normal.main,
    hypoglycemia: theme.palette.glycemia.hypoglycemia.main,
    severeHypoglycemia: theme.palette.glycemia.severeHypoglycemia.main,
  };

  const thresholdValues = {
    severeHyperglycemia: `>${glycemiaThresholds.severeHyperglycemia} mg/dL`,
    hyperglycemia: `${glycemiaThresholds.hyperglycemia} - ${glycemiaThresholds.severeHyperglycemia} mg/dL`,
    normal: `${glycemiaThresholds.hypoglycemia} - ${glycemiaThresholds.hyperglycemia} mg/dL`,
    hypoglycemia: `${glycemiaThresholds.severeHypoglycemia} - ${glycemiaThresholds.hypoglycemia} mg/dL`,
    severeHypoglycemia: `< ${glycemiaThresholds.severeHypoglycemia} mg/dL`,
  };

  // scales, memoize for performance
  const yScale = React.useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        round: true,
        domain: Object.keys(data),
        padding: 0.7,
      }),
    [yMax, data],
  );
  const xScale = React.useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        round: true,
        domain: [0, Math.max(...Object.values(data))],
      }),
    [xMax, data],
  );

  return (
    <div className={className}>
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          {Object.entries(data).map(([rangeName, ratio]) => {
            const barWidth = xScale(ratio);
            const labelX = barWidth + 10;

            const barHeight = yScale.bandwidth();
            const barX = 0;
            const barY = yScale(rangeName);
            return (
              <Group key={`bar-group-${rangeName}`}>
                <BarRounded
                  key={`bar-${rangeName}`}
                  x={barX}
                  y={barY ?? 0}
                  width={barWidth}
                  height={barHeight}
                  fill={fillColorScale[rangeName]}
                  stroke={borderColorScale[rangeName]}
                  strokeWidth="1px"
                  radius={2}
                  all={true}
                />
                <Text
                  key={`bar-data-${rangeName}`}
                  x={labelX}
                  y={(barY ?? 0) + barHeight}
                  fontSize={16}
                  fill={textColorScale[rangeName]}
                >
                  {formatNumber(ratio * 100, 0) + '%'}
                </Text>
                <Text
                  key={`bar-legend-${rangeName}`}
                  x={barX - 10}
                  y={(barY ?? 0) + barHeight}
                  fontSize={12}
                  fill={theme.palette.grey[800]}
                  textAnchor="end"
                >
                  {thresholdValues[rangeName]}
                </Text>
              </Group>
            );
          })}
        </Group>
      </svg>
    </div>
  );
};
