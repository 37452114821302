import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';

import { compareValues } from '../GlycemiaStats/Common';
import { GlycemiaStatsBGMNoData } from './GlycemiaStatsBGMNoData';

type GlycemiaStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  className?: string;
};

export const GlycemiaStatsBGM = ({
  patientId,
  from,
  to,
  className,
}: GlycemiaStatsProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: glycemiaStats } = Queries.diabetes.useGlycemiaIndicators(
    {
      patientId,
      from: from,
      to: to,
      glycemiaMeasurmentMethod: 'bgm',
    },
    {
      enabled: !!patientId,
    },
  );

  const sizeOfRange = to.diff(from, 'days').days;
  const { data: previousGlycemiaStats } =
    Queries.diabetes.useGlycemiaIndicators(
      {
        patientId,
        from: from.minus({ days: sizeOfRange + 1 }),
        to: from.minus({ days: 1 }),
        glycemiaMeasurmentMethod: 'bgm',
      },
      {
        enabled: !!patientId,
      },
    );

  if (!glycemiaStats) {
    return <GlycemiaStatsBGMNoData className={className} />;
  }

  return (
    <Card className={className}>
      <CardHeader title={t('glycemia.title')} />
      <CardContent className={styles.flexRow}>
        <StatItem
          title={t('pages.patientMonitoring.glycemia.averageMeasuresPerDay')}
          value={glycemiaStats.averageDailyMeasures as number}
          precision={1}
          variation={compareValues(
            glycemiaStats.averageDailyMeasures,
            previousGlycemiaStats?.averageDailyMeasures,
          )}
        />
        <StatItem
          title={t('pages.patientMonitoring.glycemia.averageReader')}
          value={glycemiaStats.meanGlycemia}
          unit={'mg/dL'}
          precision={0}
          variation={compareValues(
            glycemiaStats.meanGlycemia,
            previousGlycemiaStats?.meanGlycemia,
          )}
          lowerIsBetter
        />
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  flexRow: css`
    display: flex;
    gap: ${theme.spacing(8)};
    justify-content: space-between;
  `,
});
