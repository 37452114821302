import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { AGPStats } from '@/models/AGPStatsModel';
import { DiabetesParameters } from '@/models/DiabetesDataModel';

import { AgpProfileGraph } from './AgpProfile/AgpProfile';

type AgpProfileProps = {
  className?: string;
  agp: AGPStats;
  glycemiaParameters?: DiabetesParameters;
};
export const AgpProfile: React.FC<AgpProfileProps> = ({
  className,
  agp,
  glycemiaParameters,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const hasData = Object.entries(agp).length > 0 && glycemiaParameters;

  return (
    <div className={cx(styles.container, className)}>
      <Typography variant="bodyLarge">
        {t('pages.patientMonitoring.agp.title')}
      </Typography>
      {hasData ? (
        <AgpProfileGraph agp={agp} glycemiaParameters={glycemiaParameters} />
      ) : (
        <Typography variant="body">{t('charts.noData')}</Typography>
      )}
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
});
