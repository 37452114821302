import { QueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { DiabetesRepository } from '@/io/repository/DiabetesRepository';
import { InsulinRepository } from '@/io/repository/InsulinRepository.ts';
import { makeCGMDailyGraphData } from '@/models/CGMDailyGraphData.ts';
import { EditDiabetesParameters } from '@/models/DiabetesDataModel.ts';
import { makeQueryKey, stripQueryResult } from '@/queries/Queries';
import { createInfiniteQuery } from '@/queries/utils/CreateInfiniteQuery';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';
import {
  DiabetesDataParams,
  getDiabetesDataNextPageParam,
  getDiabetesDataPreviousPageParam,
} from '@/queries/utils/DiabetesQueryUtils.ts';
import { DateUtils } from '@/utils/date.ts';

type DiabetesRequest = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
};

type InsulinIndicatorsRequest = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
  insulinInjectionMethod: string;
};

type GlycemiaIndicatorRequest = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
  glycemiaMeasurmentMethod: string;
};

export class DiabetesQueries {
  constructor(
    private readonly diabetes: DiabetesRepository = new DiabetesRepository(),
    private readonly insulin: InsulinRepository = new InsulinRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useInfiniteCGMDailyGraph = createInfiniteQuery(
    'infinite-cgm-daily-graph',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISO(), to?.toISO()),
    async ({ patientId, from, to }: DiabetesDataParams) => {
      const start = from.startOf('day');
      const end = to.endOf('day');
      const [
        parametersResult,
        glycemiaResult,
        insulinResult,
        foodResult,
        activityResult,
        reportsResult,
      ] = await Promise.all([
        this.diabetes.getParameters(patientId),
        this.diabetes.getGlycemia(patientId, start, end),
        this.insulin.getInsulin(patientId, start, end),
        this.diabetes.getFood(patientId, start, end),
        this.diabetes.getActivity(patientId, start, end),
        this.diabetes.getReports(patientId, start, end),
      ]);

      return makeCGMDailyGraphData(
        start,
        end,
        stripQueryResult(parametersResult),
        stripQueryResult(glycemiaResult),
        stripQueryResult(insulinResult),
        stripQueryResult(foodResult),
        stripQueryResult(activityResult),
        stripQueryResult(reportsResult),
      );
    },
    (_, __, lastPageParam) => getDiabetesDataNextPageParam(lastPageParam),
    {
      getPreviousPageParam: (_, __, firstPageParam) =>
        getDiabetesDataPreviousPageParam(firstPageParam),
      maxPages: 50,
    },
  );

  useInfiniteBGMLogbook = createInfiniteQuery(
    'infinite-bgm-logbook',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISO(), to?.toISO()),
    async ({ patientId, from, to }: DiabetesRequest) =>
      stripQueryResult(await this.diabetes.getBGMLogbook(patientId, from, to)),
    (_, __, lastPageParam) => ({
      patientId: lastPageParam.patientId,
      from: lastPageParam.from
        .minus({
          days: DateUtils.compareDates(lastPageParam.to, lastPageParam.from),
        })
        .startOf('week'),
      to: lastPageParam.from.minus({ days: 1 }),
    }),
  );

  useParameters = createQuery(
    'parameters',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getParameters(patientId)),
  );

  useMedicalDevices = createQuery(
    'medical-devices',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getMedicalDevices(patientId)),
  );

  /**
   * @deprecated Moving to useGlycemiaIndicators
   */
  useTargetDurationStats = createQuery(
    'target-duration-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: DiabetesRequest) =>
      stripQueryResult(
        await this.diabetes.getTargetDurationStats(patientId, from, to),
      ),
  );

  /**
   * @deprecated Moving to useGlycemiaIndicators and useInsulinIndicators
   */
  useGlobalStats = createQuery(
    'global-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: DiabetesRequest) =>
      stripQueryResult(await this.diabetes.getGlobalStats(patientId, from, to)),
  );

  /**
   * @deprecated Moving to useGlycemiaIndicators
   */
  useAGPStats = createQuery(
    'agp-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: DiabetesRequest) =>
      stripQueryResult(await this.diabetes.getAGPStats(patientId, from, to)),
  );

  useGlycemiaIndicators = createQuery(
    'glycemia-indicators',
    ({ patientId, from, to, glycemiaMeasurmentMethod }) =>
      makeQueryKey(
        patientId,
        from?.toISODate(),
        to?.toISODate(),
        glycemiaMeasurmentMethod,
      ),
    async ({
      patientId,
      from,
      to,
      glycemiaMeasurmentMethod,
    }: GlycemiaIndicatorRequest) =>
      stripQueryResult(
        await this.diabetes.getGlycemiaIndicators(
          patientId,
          from,
          to,
          glycemiaMeasurmentMethod,
        ),
      ),
  );

  useInsulinIndicators = createQuery(
    'insulin-indicators',
    ({ patientId, from, to, insulinInjectionMethod }) =>
      makeQueryKey(
        patientId,
        from?.toISODate(),
        to?.toISODate(),
        insulinInjectionMethod,
      ),
    async ({
      patientId,
      from,
      to,
      insulinInjectionMethod,
    }: InsulinIndicatorsRequest) =>
      stripQueryResult(
        await this.diabetes.getInsulinIndicators(
          patientId,
          from,
          to,
          insulinInjectionMethod,
        ),
      ),
  );

  /*******************************/
  /*********** MUTATIONS *********/
  /*******************************/

  useEditParameters = createMutation(
    'edit-parameters',
    async (data: EditDiabetesParameters) =>
      stripQueryResult(await this.diabetes.editParameters(data)),
    {
      onSuccess: (parameters, { patientId }, { queryClient }) => {
        this.useParameters.manualUpdate(
          queryClient,
          patientId,
          () => parameters,
        );
        this.invalidateAllStats(queryClient, patientId);
      },
    },
  );

  useUploadFile = createMutation(
    'upload-file',
    async ({
      patientId,
      file,
      timezone,
    }: {
      patientId: string;
      file: File;
      timezone?: string;
    }) =>
      stripQueryResult(
        await this.diabetes.uploadFile(patientId, file, timezone),
      ),
  );

  /*******************************/
  /************ HELPERS **********/
  /*******************************/

  invalidateAllStats = (queryClient: QueryClient, patientId: string) => {
    this.useInfiniteCGMDailyGraph.invalidate(queryClient, { patientId });
    this.useTargetDurationStats.invalidate(queryClient, { patientId });
    this.useGlobalStats.invalidate(queryClient, { patientId });
    this.useAGPStats.invalidate(queryClient, { patientId });
  };
}
