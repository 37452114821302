import React from 'react';

import { css } from '@emotion/css';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import { Condition } from '@/models/ConditionModel.ts';
import {
  DiabetesParameters,
  DiabetesParametersType,
} from '@/models/DiabetesDataModel.ts';
import { Patient } from '@/models/PatientModel.ts';
import { Queries } from '@/queries/Queries.ts';
import { ThresholdSettings } from '@/uiKit/molecules/thresholds/ThresholdSettings.tsx';

export type AllThresholdsSettingsProps = {
  patient: Patient;
  diabetesParameters: DiabetesParameters;
  condition: Condition;
};

export const AllThresholdsSettings: React.FC<AllThresholdsSettingsProps> = ({
  patient,
  diabetesParameters,
  condition,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const updateThreshold = Queries.diabetes.useEditParameters();

  const title =
    condition.type === 'gestational'
      ? t('thresholdSettings.alertThresholds')
      : t('thresholdSettings.thresholds');

  const onEditThreshold = (threshold: DiabetesParametersType, value: number) =>
    updateThreshold.mutate(
      {
        patientId: patient.id,
        [threshold]: value,
      },
      {
        onSuccess: () =>
          TextToast.success(
            `${t(`thresholdSettings.${threshold}`)}`,
            `${t('thresholdSettings.thresholdUpdated')}: ${
              diabetesParameters[threshold]
            } => ${value}`,
          ),
      },
    );

  return (
    <Card className={styles.container}>
      <CardHeader title={title} />
      <CardContent className={styles.content}>
        <ThresholdSettings
          type="all"
          diabetesParameters={diabetesParameters}
          onSubmit={onEditThreshold}
        />
      </CardContent>
    </Card>
  );
};

const makeStyles = () => ({
  container: css`
    height: fit-content;
  `,
  content: css``,
});
