import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';

import { Col, Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';
import { GlycemiaIndicatorsDisplayType } from '@/models/DiabetesDisplayModeModel';
import { GlobalStats } from '@/models/GlobalStatsModel.ts';
import { TargetDurationStats } from '@/models/TargetDurationStatsModel';
import { AgpProfile } from '@/uiKit/molecules/graphs/AgpProfile/AgpProfile';
import {
  GlycemiaRepartitionBGM,
  GlycemiaRepartitionCGM,
} from '@/uiKit/molecules/graphs/GlycemiaBarChart/GlycemiaBarChart';
import { GlycemiaReaderValues } from '@/uiKit/organisms/GlycemiaStats/GlycemiaReaderValues';
import { GlycemiaSensorValues } from '@/uiKit/organisms/GlycemiaStats/GlycemiaSensorValue';

export type GlycemiaStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  glycemiaIndicatorsDisplayMode?: GlycemiaIndicatorsDisplayType;
  stats: GlobalStats['glycemia'];
  targetDurationStats: TargetDurationStats;
};

/**
 * @deprecated This is being switched to the ME
 */
export const GlycemiaStatsContent: React.FC<GlycemiaStatsProps> = props => {
  const styles = useStyles(makeStyles);

  const GlycemiaIndicators = () => {
    switch (props.glycemiaIndicatorsDisplayMode) {
      case 'bgm':
      case 'dg_bgm':
        return (
          <Row className={styles.statsRow}>
            <div className={styles.statsValues}>
              <GlycemiaReaderValues data={props.stats.reader} gestational />
            </div>
          </Row>
        );
      case 't12_bgm':
        return (
          <Row className={styles.statsRow}>
            <div className={styles.statsValues}>
              <GlycemiaReaderValues data={props.stats.reader} />
            </div>
            <GlycemiaRepartitionBGM
              glycemiaDistribution={
                props.targetDurationStats.reader.glycemia_distribution
              }
            />
          </Row>
        );
      case 'cgm':
      case 't12_cgm':
        return (
          <>
            <Row className={styles.statsRow}>
              <div className={styles.statsValues}>
                <GlycemiaSensorValues data={props.stats.sensor} />
              </div>
              <GlycemiaRepartitionCGM
                glycemiaDistribution={
                  props.targetDurationStats.sensor.glycemia_distribution
                }
              />
            </Row>
            <AgpProfile
              patientId={props.patientId}
              from={props.from}
              to={props.to}
            />
          </>
        );
      case undefined:
      default:
        return (
          <Row className={styles.statsRow}>
            <div className={styles.statsValues}>
              <GlycemiaSensorValues data={props.stats.sensor} />
            </div>
            <GlycemiaRepartitionCGM
              glycemiaDistribution={
                props.targetDurationStats.sensor.glycemia_distribution
              }
            />
          </Row>
        );
    }
  };

  return (
    <Col className={styles.content}>
      <GlycemiaIndicators />
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  loader: css`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
  content: css`
    width: 100%;
    overflow: clip;
    display: flex;
    flex-direction: column;
  `,
  statsRow: css`
    flex-wrap: wrap;
    width: 100%;
    row-gap: ${theme.spacing(8)};
  `,
  statsValues: css`
    flex: 1 1 50%;
  `,
  statsGraphics: css`
    flex: 0 0;
  `,
});
